<template>
  <Dialog
    ref="dialogRef"
    :dismissible="dismissible"
    :dialog-classes="dialogClasses"
    :trigger-classes="triggerClasses"
    :on-open="handleOpen"
  >
    <template #trigger>
      <slot name="trigger"></slot>
    </template>

    <template #content="{ closeDialog }">
      <div class="ajax-dialog-content w-full">
        <div class="ajax-dialog-body">
          <div v-if="error" class="error-container p-4 text-red-600 bg-red-50 rounded-lg text-center">
            <p>{{ error }}</p>
            <Button class="mt-2" variant="secondary" @click="loadContent">Retry</Button>
          </div>

          <div v-else-if="loading" class="loading-container flex justify-center items-center p-8">
            <Spinner />
          </div>

          <div v-else-if="content" v-html="content" class="content-container"></div>

          <div v-else class="empty-container p-4 text-gray-500 text-center">
            <p>No content available</p>
          </div>
        </div>

        <div v-if="$slots.footer" class="ajax-dialog-footer mt-6 pt-4 border-t border-gray-200">
          <slot name="footer" :close-dialog="closeDialog"></slot>
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted } from "vue";
import { Dialog, Spinner, Button } from "prism";
import Client from "@/modules/internal_api/client";

declare global {
  interface Window {
    closeDialog: (id?: string) => void;
    _dialogRegistry?: Record<string, any>;
  }
}

interface AjaxDialogProps {
  url: string;
  params?: Record<string, any>;
  title?: string;
  dismissible?: boolean;
  dialogClasses?: string;
  triggerClasses?: string;
  contentSelector?: string;
  dialogId?: string;
}

const props = withDefaults(defineProps<AjaxDialogProps>(), {
  params: () => ({}),
  dismissible: true,
  dialogClasses: "",
  triggerClasses: "",
  contentSelector: "",
});

const dialogRef = ref<InstanceType<typeof Dialog> | null>(null);
const content = ref<string>("");
const loading = ref<boolean>(false);
const error = ref<string>("");

onMounted(() => {
  if (typeof window !== "undefined") {
    if (!window._dialogRegistry) {
      window._dialogRegistry = {};
    }

    window._dialogRegistry[props.dialogId] = dialogRef;

    if (!window.closeDialog) {
      window.closeDialog = (id) => {
        if (id && window._dialogRegistry && window._dialogRegistry[id]) {
          const ref = window._dialogRegistry[id];
          ref.value?.closeDialog();
        }
      };
    }
  }
});

onUnmounted(() => {
  if (typeof window !== "undefined" && window._dialogRegistry) {
    delete window._dialogRegistry[props.dialogId];
  }
});

const dialogMethods = {
  open: () => dialogRef.value?.showDialog(),
  close: () => dialogRef.value?.closeDialog(),
  reload: loadContent,
};

defineExpose({
  dialogId: props.dialogId,
  open: dialogMethods.open,
  close: dialogMethods.close,
  reload: dialogMethods.reload,
});

function handleOpen() {
  if (props.url) {
    loadContent();
  }
}

async function loadContent() {
  if (!props.url) {
    error.value = "No URL provided";
    return;
  }

  loading.value = true;
  error.value = "";

  try {
    const { content: responseContent, error: responseError } = await Client.fetchAjaxDialogContent(
      props.url,
      props.contentSelector
    );

    if (responseError) {
      throw new Error(responseError);
    }

    content.value = responseContent;
  } catch (err) {
    console.error("Error loading content:", err);
    error.value = err instanceof Error ? err.message : "Failed to load content";
  } finally {
    loading.value = false;
  }
}
</script>

<style scoped>
.ajax-dialog-content {
  min-width: 300px;
  max-width: 100%;
}

@media (min-width: 640px) {
  .ajax-dialog-content {
    min-width: 400px;
  }
}
</style>
