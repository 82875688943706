import Rails from "@rails/ujs";
import ResponseParser from "./response_parser";

import { Image, ProcessingError, ProcessingState, ResultVariant, Upload, UploadError, UploadState } from "./image";
import { urlToFile } from "@/modules/utils";
import { DrawAction } from "@/stores/line";
import {
  BackgroundImageSearchError,
  BackgroundImageSearchResult,
  BackgroundImageCollection,
  errorStateFromHttpStatus,
} from "./background_search";

type SignedUrlResponse = { url: string };
type UploadToBucketResponse = { success: Boolean; url?: string };

class Client {
  static readonly defaultHeaders = {
    "x-csrf-token": Rails.csrfToken() || "",
    Accept: "application/json",
  };

  static async allImages(): Promise<Image[]> {
    const response = await fetch("/images/inline", { headers: this.defaultHeaders });
    const rawData = await response.json();
    return ResponseParser.parse(rawData);
  }

  static async getImage(id: string): Promise<Image> {
    const response = await fetch(`/images/inline/${id}`, { headers: this.defaultHeaders });
    const rawData = await response.json();
    return ResponseParser.parse(rawData)[0];
  }

  static async deleteImage(image: Image): Promise<void> {
    await fetch(`/images/${image.meta.id}`, { method: "DELETE", headers: this.defaultHeaders });
  }

  static async uploadImage(image: Image): Promise<Image> {
    const formData = this.createFormData(image.original);

    const response = await fetch("/images", {
      method: "POST",
      headers: this.defaultHeaders,
      body: formData,
    });

    if (!response.ok) {
      const errorData = await response.json();

      const error: UploadError = {
        state: UploadState.Error,
        code: `upload_error.${errorData.error_code}`,
        message: errorData.error_message,
      };

      return {
        ...image,
        original: error,
      };
    }

    const rawData = await response.json();
    return ResponseParser.parse(rawData)[0];
  }

  static async processHd(image: Image): Promise<Image> {
    const response = await fetch(`/images/${image.meta.id}/full_image/create_inline`, {
      method: "POST",
      headers: this.defaultHeaders,
    });

    if (!response.ok) {
      const error: ProcessingError = {
        state: ProcessingState.Error,
        code: `processing_error.${response.status}`,
        message: response.statusText,
      };

      return {
        ...image,
        hdResult: error,
      };
    }

    const rawData = await response.json();
    return ResponseParser.parse(rawData)[0];
  }

  static async enqueueMagicBrush(
    image: Image,
    maskBlob: Blob,
    colorBytes: Uint8Array,
    alphaBytes: Uint8Array,
    alphaUrl: string,
    action: DrawAction,
    iterationId: string,
    isMagicBrushEnabled: boolean
  ): Promise<Image> {
    const formData = this.createMagicBrushFormData(
      image,
      maskBlob,
      colorBytes,
      alphaBytes,
      alphaUrl,
      action,
      iterationId,
      isMagicBrushEnabled
    );
    formData.append("size", "preview");

    const response = await fetch(`/images/${image.meta.id}/ai_brush`, {
      method: "PUT",
      headers: this.defaultHeaders,
      body: formData,
    });

    if (!response.ok) {
      const error: ProcessingError = {
        state: ProcessingState.Error,
        code: `processing_error.${response.status}`,
        message: response.statusText,
      };

      return {
        ...image,
        magicBrushPreviewResult: error,
      };
    }

    const rawData = await response.json();
    const parsedData = ResponseParser.parse(rawData)[0];
    return parsedData;
  }

  static async enqueueMagicBrushHd(image: Image, store: any): Promise<Image> {
    const cache = store.magicBrushCache;
    const iterationId = cache.iterationId;

    const formData = this.createMagicBrushHdFormData(image, iterationId);

    const response = await fetch(`/images/${image.meta.id}/full_image/ai_brush`, {
      method: "PUT",
      headers: this.defaultHeaders,
      body: formData,
    });

    if (!response.ok) {
      const error: ProcessingError = {
        state: ProcessingState.Error,
        code: `processing_error.${response.status}`,
        message: response.statusText,
      };

      return {
        ...image,
        magicBrushHdResult: error,
      };
    }

    const rawData = await response.json();
    return ResponseParser.parse(rawData)[0];
  }

  static async uploadToBucket(image: Image, bytes: string, variant: ResultVariant): Promise<UploadToBucketResponse> {
    const size = variant === ResultVariant.Hd ? "hd" : "preview";
    const params = new URLSearchParams({
      size: size,
    });

    const response = await fetch(`/images/inline/${image.meta.id}/signed_url`, {
      method: "POST",
      headers: this.defaultHeaders,
      body: params,
    });
    if (!response.ok) {
      return { success: false };
    }

    const data: SignedUrlResponse = await response.json();
    const url = data.url;

    const fileName = variant === ResultVariant.Hd ? "hd.png" : "preview.png";
    const mimeType = "image/png";
    const file: File = await urlToFile(bytes, fileName, mimeType);

    const uploadResponse = await fetch(url, { method: "PUT", headers: { "Content-Type": mimeType }, body: file });
    if (!uploadResponse.ok) {
      return { success: false };
    }

    const accessResponse = await fetch(`/images/inline/${image.meta.id}/public_url`, {
      method: "POST",
      headers: this.defaultHeaders,
      body: params,
    });
    if (!accessResponse.ok) {
      return { success: false };
    }

    const accessData: SignedUrlResponse = await accessResponse.json();
    return { success: true, url: accessData.url };
  }

  static async uploadBackgroundToBucket(image: Image, file: File): Promise<UploadToBucketResponse> {
    const rnd = crypto.randomUUID();

    const params = new URLSearchParams({
      ext: file.name.split(".").pop(),
      rnd: rnd,
    });

    const response = await fetch(`/images/inline/${image.meta.id}/signed_background_url`, {
      method: "POST",
      headers: this.defaultHeaders,
      body: params,
    });
    if (!response.ok) {
      return { success: false };
    }

    const data: SignedUrlResponse = await response.json();
    const url = data.url;
    const mimeType = "image/png";

    const uploadResponse = await fetch(url, { method: "PUT", headers: { "Content-Type": mimeType }, body: file });
    if (!uploadResponse.ok) {
      return { success: false };
    }

    const accessResponse = await fetch(`/images/inline/${image.meta.id}/public_background_url`, {
      method: "POST",
      headers: this.defaultHeaders,
      body: params,
    });
    if (!accessResponse.ok) {
      return { success: false };
    }

    const accessData: SignedUrlResponse = await accessResponse.json();
    return { success: true, url: accessData.url };
  }

  static async rateImage(image: Image, rating: number, contribute: Boolean): Promise<Boolean> {
    const formData = new FormData();
    formData.append("image[rating]", String(rating));
    formData.append("image[contribute]", String(contribute));

    const response = await fetch(`/images/${image.meta.id}`, {
      method: "PATCH",
      headers: this.defaultHeaders,
      body: formData,
    });

    return response.ok;
  }

  static async editorExperiments(): Promise<any> {
    const params = new URLSearchParams({
      locale: window.I18n.locale,
    });

    const url = new URL("/images/experiments", window.location.origin);
    url.search = params.toString();

    const response = await fetch(url, { headers: this.defaultHeaders });

    const rawData = await response.json();
    return rawData.data || [];
  }

  private static createFormData(upload: Upload): FormData {
    const formData = new FormData();
    if (upload.file) {
      if (upload.fileName) {
        formData.append("image[original]", upload.file, upload.fileName);
      } else {
        formData.append("image[original]", upload.file);
      }
    } else if (upload.url) {
      formData.append("image[original_source_url]", upload.url);
    }
    if (upload.token) {
      formData.append("trust_token", upload.token);
    }

    formData.append("new_editor", "true");
    return formData;
  }

  private static createMagicBrushFormData(
    image: Image,
    maskBlob: Blob,
    colorBytes: Uint8Array,
    alphaBytes: Uint8Array,
    alphaUrl: string,
    action: DrawAction,
    iterationId: string,
    isMagicBrushEnabled: boolean
  ): FormData {
    const formData = new FormData();
    formData.append("iteration_id", iterationId);
    formData.append("image_url", image.original.url);
    formData.append("is_magic_brush_enabled", String(isMagicBrushEnabled));

    formData.append("mask", maskBlob);

    const maskMode = action === DrawAction.Erase ? "negative_mask" : "positive_mask";
    formData.append("mask_mode", maskMode);

    if (alphaBytes) {
      let blob = new Blob([alphaBytes], { type: "image/png" });
      formData.append("previous_alpha", blob);
    } else if (alphaUrl) {
      formData.append("previous_alpha_url", alphaUrl);
    }

    if (colorBytes) {
      let blob = new Blob([colorBytes], { type: "image/jpeg" });
      formData.append("previous_color", blob);
    }

    formData.append("new_editor", "true");

    return formData;
  }

  private static createMagicBrushHdFormData(image: Image, iterationId: string): FormData {
    const formData = new FormData();
    formData.append("image_url", image.original.url);
    formData.append("iteration_id", iterationId);

    formData.append("new_editor", "true");
    return formData;
  }

  static async fetchBackgrounds({
    searchTerm,
    page,
  }: {
    searchTerm: string;
    page: number;
  }): Promise<Array<BackgroundImageSearchResult>> {
    const url = new URL("/bg-image/search", window.location.origin);

    const params = new URLSearchParams({
      query: searchTerm,
      page: String(page),
      locale: window.I18n.locale,
    });

    url.search = params.toString();

    const response = await fetch(url, { headers: this.defaultHeaders });

    if (!response.ok) {
      const errorData = await response.json();
      const err = new BackgroundImageSearchError({
        code: response.status,
        message: errorData.msg,
        state: errorStateFromHttpStatus(response.status),
      });

      throw err;
    }

    const backgrounds = await response.json();
    return backgrounds || [];
  }

  static async fetchBackgroundCollections(): Promise<Array<BackgroundImageCollection>> {
    const url = new URL("/bg-image/collections", window.location.origin);

    const response = await fetch(url, { headers: this.defaultHeaders });

    if (!response.ok) {
      const errorData = await response.json();
      const err = new BackgroundImageSearchError({
        code: response.status,
        message: errorData.msg,
        state: errorStateFromHttpStatus(response.status),
      });

      throw err;
    }

    const collections = await response.json();
    return collections || [];
  }

  static async fetchBackgroundsByCollection({
    collection,
    page,
  }: {
    collection: string;
    page: number;
  }): Promise<Array<BackgroundImageSearchResult>> {
    const url = new URL(`/bg-image/collections/${collection}`, window.location.origin);

    const params = new URLSearchParams({
      page: String(page),
    });

    url.search = params.toString();

    const response = await fetch(url, { headers: this.defaultHeaders });

    if (!response.ok) {
      const errorData = await response.json();
      const err = new BackgroundImageSearchError({
        code: response.status,
        message: errorData.msg,
        state: errorStateFromHttpStatus(response.status),
      });

      throw err;
    }

    const backgrounds = await response.json();
    return backgrounds || [];
  }

  static async fetchAjaxDialogContent(
    url: string,
    contentSelector: string = ""
  ): Promise<{ content: string; error?: string }> {
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          ...this.defaultHeaders,
          Accept: "text/html, application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      });

      if (!response.ok) {
        throw new Error(`Server responded with ${response.status}: ${response.statusText}`);
      }

      const contentType = response.headers.get("Content-Type") || "";
      let responseContent = "";

      if (contentType.includes("application/json")) {
        const jsonData = await response.json();
        responseContent = jsonData.html || JSON.stringify(jsonData, null, 2);
      } else {
        responseContent = await response.text();
      }

      if (contentSelector) {
        responseContent = this.extractContentBySelector(responseContent, contentSelector);
      }

      return { content: responseContent };
    } catch (err) {
      console.error("Error loading dialog content:", err);
      return {
        content: "",
        error: err instanceof Error ? err.message : "Failed to load content",
      };
    }
  }

  private static extractContentBySelector(html: string, selector: string): string {
    if (!selector) return html;

    try {
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, "text/html");
      const element = doc.querySelector(selector);

      if (element) {
        return element.outerHTML;
      } else {
        console.warn(`Selector "${selector}" not found in response`);
        return html;
      }
    } catch (err) {
      console.error("Error extracting content by selector:", err);
      return html;
    }
  }
}

export default Client;
