<template>
  <PlanCard>
    <PlanContent>
      <template #title>
        <slot name="title" />
      </template>

      <template #subtitle>
        <slot name="subtitle" />
      </template>

      <template #price>
        <span class="text-typo-tertiary">{{ I18n.t("pricing.free.price") }} </span>
      </template>
      <template v-if="isYearlyBillingInterval" #billing-appendix></template>

      <template v-if="!User.signedIn()" #action>
        <Button
          variant="secondary"
          full-width
          as="link"
          href="/users/sign_in"
          :aria-label="I18n.t('pricing.free.action')"
        >
          {{ I18n.t("pricing.free.action") }}
        </Button>
      </template>

      <template v-else #action>
        <Button variant="secondary" full-width disabled :aria-label="I18n.t('pricing.free.signed_in_action')">
          {{ I18n.t("pricing.free.signed_in_action") }}
        </Button>
      </template>

      <template #plan-description>
        <slot name="plan-description" />
      </template>
    </PlanContent>
  </PlanCard>
</template>

<script setup lang="ts">
import { Button } from "prism";
import PlanCard from "./plan_card.vue";
import PlanContent from "./plan_content.vue";
import User from "@/modules/user";
import { computed, inject } from "vue";
import { BillingInterval } from "./types";

const I18n = inject("I18n");

const props = defineProps<{
  billingInterval: BillingInterval;
}>();

const isYearlyBillingInterval = computed(() => props.billingInterval === "yearly");
</script>
