<template>
  <svg width="48" height="32" viewBox="0 0 48 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.84241 5.96472C4.45961 6.05532 5.01635 6.36422 5.39015 6.82348C5.76395 7.28273 5.92419 7.85472 5.83562 8.4136L4.67811 15.7181C4.58955 16.277 4.25944 16.7769 3.7604 17.1081C3.26135 17.4392 2.63424 17.5744 2.01705 17.4838C1.39985 17.3932 0.843151 17.0842 0.469353 16.6249C0.0955544 16.1657 -0.0647202 15.5938 0.0238419 15.0349L1.18135 7.73038C1.27053 7.17177 1.60083 6.67214 2.09972 6.34112C2.59861 6.0101 3.22533 5.87473 3.84241 5.96472Z"
      fill="#FFC430"
    />
    <path
      d="M19.7447 21.9866C19.1908 21.7094 18.7672 21.2442 18.5671 20.6935C18.3671 20.1427 18.407 19.5514 18.678 19.0497L27.2746 1.11601C27.5456 0.614315 28.0256 0.24328 28.6089 0.0845378C29.1922 -0.0742042 29.8311 -0.00764906 30.3851 0.269553C30.939 0.546755 31.3626 1.01189 31.5626 1.56265C31.7627 2.11342 31.7228 2.7047 31.4518 3.20639L22.8552 21.1401C22.5837 21.6414 22.1037 22.012 21.5205 22.1707C20.9374 22.3294 20.2988 22.2632 19.7447 21.9866Z"
      fill="#FFC430"
    />
    <path
      d="M34.2158 31.1822C33.8085 30.7268 33.5947 30.1435 33.6215 29.5606C33.6483 28.9777 33.9135 28.443 34.3587 28.0741L44.1888 19.2061C44.6341 18.8372 45.2229 18.6642 45.8257 18.7254C46.4286 18.7865 46.9961 19.0767 47.4035 19.5321C47.8108 19.9875 48.0246 20.5708 47.9978 21.1537C47.9709 21.7366 47.7058 22.2713 47.2605 22.6402L37.4304 31.5082C36.9849 31.8765 36.3962 32.049 35.7935 31.9879C35.1909 31.9268 34.6235 31.637 34.2158 31.1822Z"
      fill="#FFC430"
    />
  </svg>
</template>
