import { computed, inject, Ref } from "vue";
import { Plan } from "./types";

export function usePlanButton(selectedPlan: Ref<Plan | undefined>, currentPlan: Plan | null) {
  const I18n = inject("I18n");

  const currentPlanIsFree = computed(() => currentPlan?.name === "Free");

  const isPlanChangeAvailable = computed(
    () => !!(selectedPlan.value && currentPlan && !currentPlanIsFree.value && currentPlan.id !== selectedPlan.value.id)
  );

  const isCurrentPlanYearly = computed(() => currentPlan?.billingInterval === "yearly");

  const isDisabled = computed(
    () => selectedPlan.value && currentPlan && !currentPlanIsFree.value && currentPlan.id === selectedPlan.value.id
  );

  const buttonVariant = computed(() => (isDisabled.value ? "secondary" : "primary"));

  const buttonText = computed(() => {
    if (!selectedPlan.value || !currentPlan || currentPlanIsFree.value) return I18n.t("newsletter.subscribe");

    return isPlanChangeAvailable.value ? I18n.t("pricing.switch_plan") : I18n.t("pricing.current_plan");
  });

  return {
    isCurrentPlanYearly,
    isPlanChangeAvailable,
    isDisabled,
    buttonVariant,
    buttonText,
  };
}
