<template>
  <div v-if="visible" class="fixed w-full z-50 alert-banner">
    <Alert ref="alertRef" class="rounded-none" tone="neutral" dismissible>
      {{ I18n.t("pricing.you_are_currently_subscribed_to_an_annual_plan") }}
      {{ I18n.t("pricing.to_change_annual_contact_support") }}
      <a href="/support/contact?subject=Change+my+annual+plan">{{ I18n.t("support.contact_us") }}</a>
    </Alert>
  </div>
</template>

<script setup lang="ts">
import { ref, inject } from "vue";
import { Alert } from "prism";

const I18n = inject("I18n");

const visible = ref(false);
const alertRef = ref(null);

const close = () => {
  visible.value = false;
};

const show = () => {
  visible.value = true;
  alertRef.value?.show();
};

defineExpose({
  close,
  show,
  visible,
});
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.alert-banner {
  top: 5rem;
  padding: 0 0.5rem;
}
</style>
