import Routes from "@/modules/routes";
import User from "@/modules/user";
import { ref } from "vue";
import { updateSnowplowExperimentConfigs } from "@/src/snowplow";
import { useEditorStore } from "@/stores/editor_store";
import { withExponentialBackoff } from "@/modules/utils";

export const useCredits = () => {
  const DOWNLOAD_HD_CREDIT_COST: number = 1;
  const credits = ref<number>(0);
  const isLoading = ref<boolean>(false);
  const hasFetchCreditsErrored = ref<boolean>(false);
  const store = useEditorStore();

  const fetchCredits = async () => {
    if (User.signedIn()) {
      isLoading.value = true;
      try {
        await withExponentialBackoff(
          async () => {
            const url = Routes.get("credits_info_url");
            const response = await fetch(url);

            if (!response.ok) {
              throw new Error(`GET /credits_info failed with status: ${response.status} ${response.statusText}`);
            }

            const data = await response.json();
            const fetchedCredits = data.raw_credits;
            credits.value = fetchedCredits;
            store.updateCreditsBalance(fetchedCredits);
            updateSnowplowExperimentConfigs({
              credits_info: {
                has_payg_credits: data.has_payg_credits,
                has_subscription_credits: data.has_subscription_credits,
                has_enterprise_credits: data.has_enterprise_credits,
                is_active_paid_subscriber: data.is_active_paid_subscriber,
              },
            });
            return data;
          },
          {
            retryLimit: 3,
            baseDelay: 1000,
            on: [Error],
          }
        );
        hasFetchCreditsErrored.value = false;
      } catch (error) {
        console.error("Failed to fetch credits:", error);
        hasFetchCreditsErrored.value = true;
        throw error;
      } finally {
        isLoading.value = false;
      }
    }
  };

  return {
    DOWNLOAD_HD_CREDIT_COST,
    credits,
    isLoading,
    fetchCredits,
    hasFetchCreditsErrored,
  };
};
