import { useEditorStore, ViewMode } from "@/stores/editor_store";

export const useToggleCompare = () => {
  const editorStore = useEditorStore();

  const toggleCompareBefore = (): void => {
    editorStore.viewMode = ViewMode.Before;
  };

  const toggleCompareAfter = (): void => {
    editorStore.viewMode = ViewMode.After;
  };

  return {
    toggleCompareBefore,
    toggleCompareAfter,
  };
};
