<template>
  <PlanCard>
    <PlanContent>
      <template #title> <slot name="title" /> </template>

      <template #subtitle>
        <div class="w-full mt-2">
          <SelectInput
            id="select"
            name="volume-based-plan"
            :label="I18n.t('pricing.select_label')"
            :options="planOptions"
            :model-value="selectedOption"
            @update:modelValue="updateSelectedOption"
            controlled
          />
        </div>
      </template>

      <template #price>
        {{ selectedPlan.formattedPrice }}
      </template>

      <template #price-suffix>
        <p class="text-typo-tertiary m-2">/ {{ I18n.t("pricing.price_suffix") }}</p>
      </template>

      <template v-if="billingInterval === 'yearly'" #billing-appendix>
        {{ I18n.t("pricing.billed_yearly", { x: selectedPlan.formattedYearlyPrice }) }}
      </template>

      <template #action>
        <Button
          v-if="!isPlanChangeAvailable"
          :variant="buttonVariant"
          full-width
          @click="$emit('buy', selectedPlan)"
          :disabled="isDisabled"
          :aria-label="buttonText"
        >
          {{ buttonText }}
        </Button>
        <Button
          v-else-if="isCurrentPlanYearly"
          :variant="buttonVariant"
          :disabled="isDisabled"
          full-width
          :aria-label="buttonText"
          @click="$emit('alert')"
        >
          {{ buttonText }}
        </Button>
        <AjaxDialog
          v-else
          :url="`/switch_plan/${selectedPlan.id}`"
          :params="{ view: 'detailed' }"
          dialog-classes="max-w-3xl"
          content-selector="#switch-plan-popup"
        >
          <template #trigger>
            <Button :variant="buttonVariant" :disabled="isDisabled" full-width :aria-label="buttonText">
              {{ buttonText }}
            </Button>
          </template>
        </AjaxDialog>
      </template>

      <template #plan-description>
        <slot name="plan-description" />
      </template>
    </PlanContent>
  </PlanCard>
</template>

<script setup lang="ts">
import { Button, SelectInput } from "prism";
import PlanCard from "./plan_card.vue";
import PlanContent from "./plan_content.vue";
import { BillingInterval, Plan, SelectOption } from "./types";
import { computed, inject, ref } from "vue";
import AjaxDialog from "../prism/ajax_dialog.vue";
import { usePlanButton } from "./plan_button_utils";

const props = defineProps<{
  plans: Plan[];
  currentPlan: Plan | null;
  billingInterval: BillingInterval;
  buy(plan: Plan): void;
  alert(): void;
}>();

const I18n = inject("I18n");

const parsePlanToOption = (plan: Plan): SelectOption => ({
  label: `${plan.monthlyCredits.toLocaleString(I18n.locale)} ${
    plan.monthlyCredits > 1 ? I18n.t("pricing.credits") : I18n.t("pricing.credit")
  }`,
  value: `${plan.monthlyCredits}`,
});

const planOptions = computed(() => props.plans.map(parsePlanToOption));

// Initialize selectedOption with current plan if it exists, otherwise use first plan
const selectedOption = ref<SelectOption>(
  props.currentPlan && props.plans.some((plan) => plan.id === props.currentPlan?.id)
    ? parsePlanToOption(props.currentPlan)
    : planOptions.value[0]
);

const updateSelectedOption = (value: SelectOption) => {
  selectedOption.value = value;
};

const selectedPlan = computed(() => {
  return props.plans.find((plan) => plan.monthlyCredits === Number(selectedOption.value?.value));
});

const { isPlanChangeAvailable, buttonVariant, buttonText, isDisabled, isCurrentPlanYearly } = usePlanButton(
  selectedPlan,
  props.currentPlan
);
</script>
