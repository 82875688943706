<template>
  <!-- Start bottom bar Desktop -->
  <div class="hidden md:flex w-100">
    <div class="flex gap-4 items-center py-2 rounded-2xl w-100 justify-center">
      <!-- Zoom Buttons -->
      <div class="flex items-center gap-2 mr-2">
        <span>
          <IconButton
            variant="secondary"
            :disabled="!props.zoomable.canZoomOut.value"
            :title="I18n.t('editor.zoom_out')"
            @click="props.zoomable.zoomOut"
            ><MinusIcon
          /></IconButton>
          <IconButton
            variant="secondary"
            :disabled="!props.zoomable.canZoomIn.value"
            :title="I18n.t('editor.zoom_in')"
            @click="props.zoomable.zoomIn"
            ><PlusIcon
          /></IconButton>
        </span>
      </div>
      <!-- End Zoom Buttons -->
      <span class="text-secondary text-base h-8 w-[1px] rounded-full bg-secondary"></span>
      <!-- Toggle Compare -->
      <IconButton
        variant="secondary"
        :title="I18n.t('editor.hold_to_compare')"
        :disabled="props.isProcessing"
        @mousedown="toggleCompareBefore"
        @mouseup="toggleCompareAfter"
        @touchstart="toggleCompareBefore"
        @touchend="toggleCompareAfter"
        target-size="lg"
        ><CompareIcon
          :class="{
            'text-brand-typo': store.viewMode == ViewMode.Before && !props.isProcessing,
            'text-typo': store.viewMode == ViewMode.After && !props.isProcessing,
          }"
      /></IconButton>
      <!-- End Toggle Compare-->
      <span class="text-secondary text-base h-8 w-[1px] rounded-full bg-secondary"></span>
      <!-- Undo/Redo Buttons -->
      <div class="flex items-center gap-2 mr-2">
        <span>
          <IconButton
            variant="secondary"
            :disabled="!props.currentPersistentStore?.canUndo"
            :title="I18n.t('editor.undo')"
            @click="props.currentPersistentStore?.undo"
            ><UndoIcon
          /></IconButton>
          <IconButton
            variant="secondary"
            :disabled="!props.currentPersistentStore?.canRedo"
            :title="I18n.t('editor.redo')"
            @click="props.currentPersistentStore?.redo"
            ><RedoIcon
          /></IconButton>
        </span>
      </div>
      <!-- End Undo/Redo Buttons -->
    </div>
  </div>
</template>

<script setup lang="ts">
import "@/src/i18n";
import { UseZoomableReturnType } from "@/composables/zoomable";
import { useEditorStore, ViewMode } from "@/stores/editor_store";
import { PersistentStore } from "@/stores/persistent_store";
import { IconButton, CompareIcon, RedoIcon, UndoIcon, PlusIcon, MinusIcon } from "prism";
import { useToggleCompare } from "@/composables/toggle_compare";

interface BottomPanelProps {
  currentPersistentStore: PersistentStore | null;
  zoomable: UseZoomableReturnType;
  isProcessing: boolean;
}

const store = useEditorStore();
const props = defineProps<BottomPanelProps>();
const { toggleCompareBefore, toggleCompareAfter } = useToggleCompare();
</script>
