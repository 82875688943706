import Cookie from "../src/cookie.js";
import {
  newTracker,
  setUserId,
  trackSelfDescribingEvent,
  addGlobalContexts,
  removeGlobalContexts,
} from "@snowplow/browser-tracker";
import { setupTracking } from "kaleido-event-tracker";
import User from "@/modules/user";

// Delete old tracker queues to prevent sending old events
// TODO: Remove this after a while, when we are sure that all users have the new version of the tracker
// https://canvadev.atlassian.net/browse/RMV-3369
const items = [
  "snowplowOutQueue_ce_post2",
  "snowplowOutQueue_ce_post2.expires",
  "snowplowOutQueue_ue_post2",
  "snowplowOutQueue_ue_post2.expires",
  "snowplowOutQueue_pv_post2",
  "snowplowOutQueue_pv_post2.expires",
];

if (localStorage) {
  items.forEach((item) => {
    localStorage.removeItem(item);
  });
}

const snowplowMetaTag = document.querySelector("meta[property='snowplow']");
const getSnowplowConfig = () => JSON.parse(snowplowMetaTag.getAttribute("content"));
let snowplowConfig = getSnowplowConfig();

class Context {
  static context = {
    schema: "iglu:bg.remove/rbg_global_context/jsonschema/1-0-4",
  };

  static setContext(ctx) {
    this.context = ctx;
  }

  static getContext() {
    return this.context;
  }
}

export const updateSnowplowExperimentConfigs = ({ rbg_experiments = [], credits_info = {} } = {}) => {
  const experiment_names = rbg_experiments.map((exp) => exp.experiment_name);
  const filteredExperiments = snowplowConfig.rbg_experiments.filter(
    (i) => !experiment_names.includes(i.experiment_name)
  );

  snowplowConfig.rbg_experiments = [...filteredExperiments, ...rbg_experiments];
  snowplowConfig = { ...snowplowConfig, ...credits_info };

  snowplowMetaTag.setAttribute("content", JSON.stringify(snowplowConfig));

  const eventsIdentifier = snowplowConfig.events_identifier;
  removeGlobalContexts([Context.getContext()], [eventsIdentifier]);

  Context.setContext({
    schema: "iglu:bg.remove/rbg_global_context/jsonschema/1-0-4",
    data: snowplowConfigData(snowplowConfig),
  });
  addGlobalContexts([Context.getContext()], [eventsIdentifier]);
};

const customTrackSelfDescribingEvent = (event, trackers) => {
  const image_id = event.event.data.image_id;
  if (image_id && image_id.startsWith("temp-")) {
    // if the event contains a property called image_id that starts with 'temp-' then we ignore it
    return;
  }

  trackSelfDescribingEvent(event, trackers);
};

const snowplowConfigData = (snowplowConfig) => {
  let data = {
    vcs_ref: snowplowConfig.vcs_ref,
    build_date: snowplowConfig.build_date,
    version: snowplowConfig.version,
    rbg_feature_flags: snowplowConfig.rbg_feature_flags,
    rbg_experiments: snowplowConfig.rbg_experiments,
    is_mobile_app: User.mobileApp(),
    android_app_version: User.androidAppVersion(),
  };
  if (User.signedIn()) {
    data = {
      ...data,
      has_payg_credits: snowplowConfig.has_payg_credits,
      has_subscription_credits: snowplowConfig.has_subscription_credits,
      is_active_paid_subscriber: snowplowConfig.is_active_paid_subscriber,
      has_enterprise_credits: snowplowConfig.has_enterprise_credits,
    }
  }

  return data;
}

if (snowplowConfig.enabled) {
  const eventsEndpoint = snowplowConfig.events_endpoint;
  const eventsIdentifier = snowplowConfig.events_identifier;

  newTracker(eventsIdentifier, eventsEndpoint, {
    appId: "remove.bg",
    platform: "web",
    withCredentials: false,
    anonymousTracking: !Cookie.consentGivenFor("performance"),
    eventMethod: "post",
    cookieLifetime: 2 * 365 * 24 * 60 * 60 * 1000,
    contexts: {
      webPage: true,
    },
  });
  Context.setContext({
    schema: "iglu:bg.remove/rbg_global_context/jsonschema/1-0-4",
    data: snowplowConfigData(snowplowConfig),
  });
  addGlobalContexts([Context.getContext()], [eventsIdentifier]);
  setUserId(snowplowConfig.user_id, [eventsIdentifier]);

  setupTracking(customTrackSelfDescribingEvent, [eventsIdentifier], snowplowConfig.custom_events_enabled);
} else {
  const noOpTracker = () => {};
  setupTracking(noOpTracker, [], false);
}
