<template>
  <div id="new-header" class="block md:hidden !bg-white !bg-opacity-90 fixed w-full z-50 top-0 px-2 relative">
    <Container full-width-on-mobile>
      <div class="flex justify-between items-center p-2" :class="headerWrapperClasses">
        <!-- Start Left -->
        <NavLogo :href="User.mobileApp() ? 'javascript:' : Routes.get('root_url')">
          <span class="sr-only">remove.bg</span>
          <LogoRBGIconOnly />
          <!-- <LogoRBG v-else style="max-height: 60px" /> -->
        </NavLogo>

        <div class="flex">
          <div
            v-if="store.selectedImage !== null"
            class="action-btn-wrapper flex custom-gap items-center"
            :class="actionIconWrapperClass"
          >
            <IconButton
              @mousedown="toggleCompareBefore"
              @mouseup="toggleCompareAfter"
              @touchstart="toggleCompareBefore"
              @touchend="toggleCompareAfter"
              variant="secondary"
              ><CompareIcon
                :class="{
                  'text-brand-typo': store.viewMode == ViewMode.Before,
                  'text-typo': store.viewMode == ViewMode.After,
                }"
            /></IconButton>
            <IconButton
              variant="secondary"
              :disabled="!currentPersistentStore?.canUndo"
              @click="currentPersistentStore?.undo"
              ><UndoIcon
            /></IconButton>
            <IconButton
              variant="secondary"
              :disabled="!currentPersistentStore?.canRedo"
              @click="currentPersistentStore?.redo"
              ><RedoIcon
            /></IconButton>

            <DownloadMenu
              v-if="store.openedPanel !== null"
              :is-processing="isProcessing"
              :is-processing-hd-download="store.isProcessingHdDownload"
              :is-processing-preview-download="store.isProcessingPreviewDownload"
              :is-hd-image-processed="store.selectedImage.hdResult.state === ProcessingState.Finished"
              :is-mobile="true"
              :hd-resolution="hdResolution"
              :preview-resolution="previewResolution"
              :download-hd="downloadHd"
              :download-preview="downloadPreview"
              :hd-download-enabled="hdDownloadEnabled"
            />
          </div>
          <span v-if="User.mobileApp()" class="action-btn-wrapper">
            <Dropdown align-right>
              <template v-slot:trigger>
                <IconButton variant="secondary"><OverflowIcon /></IconButton>
              </template>
              <template v-slot:content>
                <DropdownBox>
                  <ul class="overflow-dropdown flex flex-col text-base !p-3 mb-0">
                    <template v-if="User.signedIn()">
                      <li>
                        <a href="removebg://account" class="text-typo">{{ I18n.t("navigation.my_account") }}</a>
                      </li>
                      <li>
                        <a href="removebg://logout" class="text-typo">{{ I18n.t("navigation.logout") }}</a>
                      </li>
                    </template>
                    <li v-else>
                      <a href="removebg://login" class="text-typo">{{ I18n.t("navigation.login") }}</a>
                    </li>
                  </ul>
                </DropdownBox>
              </template>
            </Dropdown>
          </span>
        </div>
        <!-- End Left -->
      </div>
    </Container>
  </div>
</template>

<script setup lang="ts">
import { NavLogo, IconButton, CompareIcon, Container, RedoIcon, UndoIcon, LogoRBG, Dropdown, DropDownbox } from "prism";
import LogoRBGIconOnly from "../prism/rbg_icon_only_logo.vue";
import OverflowIcon from "../prism/overflow_icon.vue";
import Routes from "@/modules/routes";
import { useEditorStore, ViewMode } from "@/stores/editor_store";
import User from "@/modules/user";
import { computed } from "vue";
import { useToggleCompare } from "@/composables/toggle_compare";
import DownloadMenu from "./editor_components/download_controls/download_menu.vue";
import { PersistentStore } from "@/stores/persistent_store";
import { ProcessingState } from "@/modules/internal_api/image";

interface HeaderProps {
  currentPersistentStore?: PersistentStore;
  downloadPreview: (force_share?: boolean) => Promise<void>;
  downloadHd: (closeDialog?: () => void) => Promise<void>;
}

const props = defineProps<HeaderProps>();
const store = useEditorStore();

const { toggleCompareBefore, toggleCompareAfter } = useToggleCompare();

const showIconOnlyRBGLogo = () => {
  return User.mobileApp() && store.selectedImage;
};

const isProcessing = computed(() => {
  return store.isPreviewProcessing || store.isMagicBrushPreviewProcessing || store.isApplyingBackgroundChanges;
});

const headerWrapperClasses = computed(() => ({
  "p-2": showIconOnlyRBGLogo(),
}));

const actionIconWrapperClass = computed(() => ({
  "action-icons-gap": showIconOnlyRBGLogo(),
}));

const previewResolution = computed(() => {
  return `${store.selectedImage.meta.previewWidth} x ${store.selectedImage.meta.previewHeight}`;
});

const hdResolution = computed(() => {
  return `${store.selectedImage.meta.hdWidth} x ${store.selectedImage.meta.hdHeight}`;
});

const hdDownloadEnabled = computed(() => {
  return store.selectedImage.meta.fullAvailable;
});
</script>

<style scoped>
#new-header {
  transition: top 0.5s;
}

.custom-gap {
  gap: 8px;
}

.action-icons-gap {
  gap: 8px !important;
}

.overflow-dropdown {
  width: max-content;
  gap: 0.75rem;
}

@media (max-width: 375px) {
  .custom-gap {
    gap: 0;
  }
}
</style>
