<template>
  <div :class="cardClasses" class="rounded-4xl p-6 flex flex-col items-center gap-4 bg-white relative">
    <PlanHighlightIcon v-if="highlighted" class="highlighted-icon" />
    <slot />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import PlanHighlightIcon from "./plan_highlight_icon.vue";
const props = defineProps<{
  highlighted?: boolean;
  outline?: boolean;
}>();

const cardClasses = computed(() => ({
  highlighted: props.highlighted,
  border: props.outline,
}));
</script>

<style scoped lang="scss">
.highlighted {
  border: 3px solid #ffc83e;
}

.highlighted-icon {
  position: absolute;
  top: -2.25rem;
  right: -1.75rem;

  @media (max-width: 992px) {
    display: none;
  }
}
</style>
