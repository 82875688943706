<template>
  <section class="px-6 mt-4">
    <div class="enterprise-section border rounded-4xl p-8">
      <div class="grid enterprise-section-grid gap-8">
        <div>
          <h3 class="text-xl !pb-2 font-body">{{ I18n.t("pricing.enterprise_solutions.title") }}</h3>

          <p class="text-typo-secondary mt-2">
            {{ I18n.t("pricing.enterprise_solutions.description_part_one") }}
          </p>
          <p class="text-typo-secondary mt-2 flex gap-2">
            <LightningIcon class="text-brand" />{{ I18n.t("pricing.enterprise_solutions.description_part_two") }}
          </p>
          <p class="text-typo-secondary mt-2 flex gap-2">
            <LightningIcon class="text-brand" />{{ I18n.t("pricing.enterprise_solutions.description_part_three") }}
          </p>
          <p class="text-typo-secondary mt-2">{{ I18n.t("pricing.enterprise_solutions.description_part_four") }}</p>

          <Button as="link" href="/enterprise/contact" class="mt-4">
            {{ I18n.t("pricing.enterprise_solutions.lets_talk") }}
          </Button>
        </div>
        <div class="rounded-4xl gray-bg h-full">
          <div class="logo-container gap-6 mx-auto">
            <div class="logo-item logo-right">
              <img :src="logoFuji" alt="Fuji" style="height: 1rem" aria-label="Fuji" />
            </div>
            <div class="logo-item logo-left">
              <img :src="logoBosch" alt="Bosch" style="height: 1.25rem" aria-label="Bosch" />
            </div>
            <div class="logo-item logo-right">
              <img :src="logoAudi" alt="Audi" aria-label="Audi" />
            </div>
            <div class="logo-item logo-left">
              <img :src="logoEbay" alt="Ebay" style="height: 2rem" aria-label="Ebay" />
            </div>
            <div class="logo-item logo-right">
              <img :src="logoDouglas" alt="Douglas" style="height: 1rem" aria-label="Douglas" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { inject } from "vue";
import { Button, LightningIcon } from "prism";

import logoAudi from "@assets/images/logos/logo_audi.png";
import logoFuji from "@assets/images/logos/logo_fujifilm.png";
import logoEbay from "@assets/images/logos/logo_ebay.png";
import logoDouglas from "@assets/images/logos/logo_douglas.png";
import logoBosch from "@assets/images/logos/logo_bosch_new.png";

const I18n = inject("I18n");
</script>

<style scoped lang="scss">
.enterprise-section {
  max-width: 48rem;
  margin: 0 auto;
}

.enterprise-section-grid {
  grid-template-columns: 1fr 16rem;

  @media (max-width: 640px) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}

.gray-bg {
  background-color: #f1f3f4;
}

.logo-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2.5rem 0;
  max-width: 11rem;

  img {
    max-height: 2.5rem;
  }

  @media (max-width: 320px) {
    padding: 2.5rem 1rem;
  }
}

.logo-item {
  display: flex;
  width: 100%;
}

.logo-right {
  justify-content: flex-end;
}

.logo-left {
  justify-content: flex-start;
}
</style>
