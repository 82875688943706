<template>
  <div class="billing-switch-container">
    <div class="billing-switch-pill shadow-lg">
      <Button
        @click="updateValue(options[0].value)"
        :variant="modelValue === options[0].value ? 'secondary' : 'ghost'"
        :aria-label="options[0].label"
        >{{ options[0].label }}</Button
      >
      <Button
        @click="updateValue(options[1].value)"
        :variant="modelValue === options[1].value ? 'secondary' : 'ghost'"
        :aria-label="options[1].label"
        >{{ options[1].label }}
        <template #end>
          <Badge>
            {{ I18n.t("pricing.save_x_percent", { x: "10" }) }}
          </Badge>
        </template>
      </Button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, inject } from "vue";
import { Button, Badge } from "prism";
import { SelectOption } from "./types";
defineProps<{
  modelValue: string;
  options: SelectOption[];
}>();

const I18n = inject("I18n");

const emit = defineEmits<{
  (e: "update:modelValue", value: string): void;
}>();

const updateValue = (value: string) => {
  emit("update:modelValue", value);
};
</script>

<style scoped>
.billing-switch-container {
  display: flex;
  justify-content: center;
}

.billing-switch-pill {
  position: relative;
  display: flex;
  background-color: #ffffff;
  border-radius: 9999px;
  padding: 0.5rem;
  gap: 0.5rem;
}
.billing-switch-option {
  flex: 1;
  text-align: center;
  padding: 0.5rem 1rem;
  cursor: pointer;
  z-index: 1;
  transition: color 0.3s ease;
  font-weight: 600;
  border-radius: 9999px;
}

.billing-switch-slider {
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
  width: calc(50% - 0.5rem);
  height: calc(100% - 0.5rem);
  border-radius: 9999px;
  transition: transform 0.3s ease;
  z-index: 0;
}

.billing-switch-slider.right {
  transform: translateX(100%);
}
</style>
