<template>
  <PlanCard :highlighted="props.highlighted && !alreadySubscribed">
    <PlanContent>
      <template #title>
        <slot name="title" />
      </template>

      <template #subtitle>
        <slot name="subtitle" />
      </template>

      <template #price>
        {{ props.plan.formattedPrice }}
      </template>

      <template #price-suffix>
        <p class="text-typo-tertiary m-2">/ {{ I18n.t("pricing.price_suffix") }}</p>
      </template>

      <template #action>
        <Button
          v-if="!isPlanChangeAvailable"
          :variant="buttonVariant"
          :disabled="isDisabled"
          full-width
          :aria-label="buttonText"
          @click="$emit('buy', props.plan)"
        >
          {{ buttonText }}
        </Button>
        <Button
          v-else-if="isCurrentPlanYearly"
          :variant="buttonVariant"
          :disabled="isDisabled"
          full-width
          :aria-label="buttonText"
          @click="$emit('alert')"
        >
          {{ buttonText }}
        </Button>
        <AjaxDialog
          v-else
          :url="`/switch_plan/${props.plan.id}`"
          :params="{ view: 'detailed' }"
          dialog-classes="max-w-3xl"
          content-selector="#switch-plan-popup"
          dialog-id="switch-plan-popup"
        >
          <template #trigger>
            <Button :variant="buttonVariant" :disabled="isDisabled" full-width :aria-label="buttonText">
              {{ buttonText }}
            </Button>
          </template>
        </AjaxDialog>
      </template>
      <template #billing-appendix v-if="$slots['billing-appendix']">
        <slot name="billing-appendix" />
      </template>

      <template #plan-description>
        <slot name="plan-description" />
      </template>
    </PlanContent>
  </PlanCard>
</template>

<script setup lang="ts">
import { Button } from "prism";
import PlanCard from "./plan_card.vue";
import PlanContent from "./plan_content.vue";
import { Plan } from "./types";
import AjaxDialog from "../prism/ajax_dialog.vue";
import { usePlanButton } from "./plan_button_utils";
import { computed, inject } from "vue";

const props = defineProps<{
  plan: Plan;
  currentPlan?: Plan;
  buy(plan: Plan): void;
  alert(): void;
  highlighted?: boolean;
}>();

const planRef = computed(() => props.plan);
const I18n = inject("I18n");
const { isPlanChangeAvailable, buttonVariant, buttonText, isDisabled, isCurrentPlanYearly } = usePlanButton(
  planRef,
  props.currentPlan
);

const alreadySubscribed = computed(() => props.currentPlan?.id === props.plan.id);
</script>
