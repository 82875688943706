<template>
  <!-- NEW SIDE PANEL -->
  <div id="side-panel" class="hidden md:flex flex-col gap-12" style="min-width: 24rem">
    <!-- Start ToolBox -->
    <div class="z-5 absolute top-0">
      <!-- Panel manager for desktop -->
      <PanelManager
        v-if="!isMobileLarge"
        :current-persistent-store="currentPersistentStore"
        :restore-original-result="restoreOriginalResult"
        :editor="editor"
        :toggle-panel="togglePanel"
        :stage="stage"
        :scale="initialScale"
      />
    </div>
    <!-- End ToolBox -->

    <!-- Start Buttons -->
    <div v-if="enableUI" class="flex flex-col items-start gap-3 toolbox-btns">
      <ActionButtonPlaceholder v-if="props.isProcessing"></ActionButtonPlaceholder>
      <ActionButton
        v-else
        @click="props.togglePanel('addBackground')"
        :badgeText="Flipper.isEnabled('background_image_search') ? capitalizeString(I18n.t('misc.new_badge')) : ''"
      >
        <template #icon>
          <AddIcon
            v-if="
              !props.currentPersistentStore?.selectedBackgroundPhotoUrl &&
              !props.currentPersistentStore?.selectedBackgroundColor
            "
          />
          <span v-else class="w-full h-full" :style="props.backgroundActionButtonStyle" />
        </template>
        <template #title>{{ I18n.t(`editor.background`) }}</template>
      </ActionButton>
      <ActionButtonPlaceholder v-if="props.isProcessing"></ActionButtonPlaceholder>
      <span
        v-if="hasExceededMagicBrushIterations(store.selectedImage, props.refreshTippy)"
        class="hover-tooltip"
        :data-tippy-content="`${I18n.t('ai_brush.error.iterations_exceeded')}`"
        data-tippy-placement="bottom"
      >
        <ActionButton
          v-if="!props.isProcessing"
          :disabled="hasExceededMagicBrushIterations(store.selectedImage, props.refreshTippy)"
        >
          <template #icon>
            <BrushIcon />
          </template>
          <template #title>{{ I18n.t(`editor.erase_restore`) }} </template>
        </ActionButton>
      </span>
      <template v-else>
        <ActionButton
          v-if="!props.isProcessing"
          @click="props.togglePanel('eraseRestore')"
          :disabled="hasExceededMagicBrushIterations(store.selectedImage, props.refreshTippy)"
        >
          <template #icon>
            <BrushIcon />
          </template>
          <template #title>{{ I18n.t(`editor.erase_restore`) }} </template>
        </ActionButton>
      </template>
      <template>
        <ActionButtonPlaceholder v-if="props.isProcessing"></ActionButtonPlaceholder>
        <ActionButton v-else @click="props.togglePanel('fx')">
          <template #icon>
            <FxIcon />
          </template>
          <template #title>{{ I18n.t(`editor.apply_effects`) }}</template>
        </ActionButton>
      </template>
      <ActionButtonPlaceholder v-if="props.isProcessing"></ActionButtonPlaceholder>
      <CanvaCta
        v-else
        :getDataURL="props.getDataURL"
        :stage="props.stage"
        :stageHd="props.stageHd"
        :image="store.selectedImage"
        :reset-zoom="props.resetZoom"
        :refresh-all-hd-layers="props.refreshAllHdLayers"
        :persistent-store="props.currentPersistentStore"
      />
    </div>
    <!-- End Buttons -->

    <!-- Start Error -->
    <div v-if="props.hasError" class="z-5 absolute top-2 w-[328px]"><Error /></div>
    <!-- End Error -->
  </div>
</template>

<script setup lang="ts">
import "@/src/i18n";
import { Panel, useEditorStore } from "@/stores/editor_store";
import { PersistentStore } from "@/stores/persistent_store";
import { useMonitorSize } from "@/composables/monitor_size";
import { ActionButton, ActionButtonPlaceholder, BrushIcon, AddIcon, FxIcon } from "prism";
import PanelManager from "../panel_manager.vue";
import { computed, inject, StyleValue } from "vue";
import { hasExceededMagicBrushIterations } from "@/modules/internal_api/image";
import { Stage } from "konva/lib/Stage";
import { Image } from "@/modules/internal_api/image";
import CanvaCta from "@/components/canva_cta.vue";
import Error from "@/components/upload/error.vue";
import Flipper from "@/modules/flipper";
import { capitalizeString } from "@/modules/utils";

const I18n = inject("I18n");

interface SidePanelProps {
  currentPersistentStore: PersistentStore | null;
  isProcessing: boolean;
  backgroundActionButtonStyle: StyleValue;
  hasError: boolean;
  scale: number;
  stage: Stage;
  stageHd: Stage;
  credits: number;
  editor: HTMLElement;
  initialScale: number;
  togglePanel: (id: Panel) => void;
  refreshTippy: () => void;
  loadCreditsAndUpdatePreview: () => Promise<void>;
  getDataURL: (image: Image, stage: any, element: any) => string;
  resetZoom: () => void;
  refreshAllHdLayers: (image: Image) => Promise<void>;
}

const props = defineProps<SidePanelProps>();
const { isMobileLarge } = useMonitorSize();

const store = useEditorStore();

const restoreOriginalResult = (): void => {
  props.currentPersistentStore?.reset();
};

const enableUI = computed(() => {
  return !props.hasError && store.openedPanel === null;
});
</script>
