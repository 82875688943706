<template>
  <section class="flex flex-col gap-6 items-center !p-6 gray-bg">
    <Heading level="h3" class="text-center"> {{ I18n.t("pricing.payg.section_heading") }}</Heading>
    <div class="payg-section w-full rounded-4xl bg-white p-8 my-4">
      <div>
        <h3 class="text-xl !pb-2 font-body">{{ I18n.t("pricing.payg.title") }}</h3>
        <div class="text-typo-secondary" v-html="I18n.t('pricing.payg.description_html')"></div>
      </div>
      <div>
        <PaygProductSelect :products="products" />
      </div>
    </div>
  </section>
  <div class="gray-bg-separator gray-bg w-full bg-seperator-end bg-repeat-x"></div>
</template>
<script setup lang="ts">
import { computed, inject, onMounted } from "vue";
import { ProductsData } from "./types";
import PaygProductSelect from "./payg_product_select.vue";
import { Heading } from "prism";

const I18n = inject("I18n");

const props = defineProps<{
  productsData: string;
}>();

onMounted(() => {
  const shouldAutobuyProduct = computed(() => productsData.value.shouldAutobuyProduct);
  const selectedProductForAutobuy = computed(() => productsData.value.selectedProductForAutobuy);
  if (shouldAutobuyProduct.value) {
    window.doPurchase(selectedProductForAutobuy.value?.pProductId, "product");
  }
});

const productsData = computed(() => JSON.parse(props.productsData) as ProductsData);
const products = computed(() => productsData.value.products);
</script>

<style scoped lang="scss">
.payg-section {
  max-width: 48rem;
  display: grid;
  grid-template-columns: 1fr 17rem;
  gap: 2rem;

  @media (max-width: 640px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.gray-bg {
  background-color: #f1f3f4;
}

.gray-bg-separator {
  height: 1.5rem;
}
</style>
