<template>
  <Container v-if="store.openedPanel === null" full-width-on-mobile class="md:hidden">
    <div class="image-navigation-wrapper flex items-center relative px-2 py-2 align-items-start">
      <UploadButton
        v-if="store.images.length !== 0"
        @click="uploadFile"
        id="mobile-upload"
        class="mobile-upload-btn sm:!mb-0 !m-0"
      />
      <div class="image-gallery sm:!mt-2 sm:!mb-0 overflow-x-scroll hide-scrollbars">
        <div class="flex image-gallery-wrapper pt-2" id="image-gallery-wrapper">
          <div v-for="(image, index) in images" :key="image.meta.id">
            <ImageButton
              v-if="image.previewResult.state === ProcessingState.Processing"
              :selected="store.selectedImage === image"
              :id="imageId(image)"
              @click="select($event, image, () => {})"
              class="relative"
            >
              <div class="bg-typo flex justify-center items-center bg-opacity-80 absolute w-full h-full">
                <LoadingIcon class="text-white animate-spin" />
              </div>
              <HTMLImage
                :src="image.meta.preview"
                img-classes="object-cover !w-12 !h-12 sm:!w-16 sm:!h-16 bg-white checkerboard"
              />
            </ImageButton>

            <ImageButton
              v-else-if="hasError(image)"
              :selected="store.selectedImage === image"
              :id="imageId(image)"
              @click="
                store.selectedImage === image ? deleteImage(store.selectedImage) : select($event, image, () => {})
              "
            >
              <template #action>
                <TrashIcon
                  v-if="store.selectedImage === image"
                  class="hover:!text-newBlue-primary-foreground active:!text-newBlue-primary-foreground"
                />
                <WarningIcon v-else />
              </template>

              <HTMLImage
                :src="image.meta.preview"
                img-classes="object-cover !w-12 !h-12 sm:!w-16 sm:!h-16 bg-white checkerboard"
              />
            </ImageButton>

            <!-- Delete confirmation dialog - only for processed HD images-->
            <Dialog
              v-else-if="store.selectedImage === image && requiresDeleteConfirmation"
              trigger-classes="focus:outline-none"
            >
              <template #trigger>
                <ImageButton
                  :selected="store.selectedImage === image"
                  :id="imageId(image)"
                  @click="select($event, image, () => {})"
                >
                  <HTMLImage
                    :src="image.meta.preview"
                    img-classes="object-cover !w-12 !h-12 sm:!w-16 sm:!h-16 bg-white checkerboard"
                  />
                  <template #action>
                    <TrashIcon />
                  </template>
                </ImageButton>
              </template>
              <template v-slot:content="{ closeDialog }">
                <div class="flex flex-col gap-y-6 w-full max-h-[680px]">
                  <Heading level="h4">{{ I18n.t("editor.are_you_sure") }}</Heading>
                  <p>{{ I18n.t("editor.you_purchased") }}</p>
                  <div class="sm:flex sm:flex-row-reverse sm:gap-x-3 space-y-3 sm:space-y-0 grow">
                    <Button
                      fullWidth
                      class="mb-2"
                      type="submit"
                      @click.prevent="() => deleteImage(store.selectedImage, closeDialog)"
                      >{{ I18n.t("editor.delete") }}</Button
                    >
                    <Button fullWidth autofocus variant="secondary" type="reset" @click="closeDialog">{{
                      I18n.t("editor.cancel")
                    }}</Button>
                  </div>
                </div>
              </template>
            </Dialog>
            <ImageButton
              v-else
              :selected="store.selectedImage === image"
              :id="imageId(image)"
              @click="
                store.selectedImage === image ? deleteImage(store.selectedImage) : select($event, image, () => {})
              "
            >
              <HTMLImage
                :src="image.meta.preview"
                img-classes="object-cover !w-12 !h-12 sm:!w-16 sm:!h-16 bg-white checkerboard"
              />
              <template #action>
                <TrashIcon />
              </template>
            </ImageButton>
          </div>
        </div>
      </div>
    </div>
  </Container>
</template>

<script setup lang="ts">
import { computed, onUpdated } from "vue";
import {
  Button,
  Dialog,
  Heading,
  Container,
  IconButton,
  Image as HTMLImage,
  ImageButton,
  TrashIcon,
  UploadButton,
  WarningIcon,
  LoadingIcon,
} from "prism";
import { Image, ProcessingState, UploadState } from "@/modules/internal_api/image";
import Client from "@/modules/internal_api/client";
import { rbgRemoveImageV100 } from "kaleido-event-tracker";

import { useEditorStore } from "@/stores/editor_store";

const store = useEditorStore();

const images = computed(() => store.images);

onUpdated(() => {
  scrollToSelectedImage();
});

const select = (event: any, image: Image, closeDialog: () => void) => {
  store.selectImage(image);
  closeDialog();
};

const hasError = (image: Image) => {
  const originalCheck = image.original.state === UploadState.Error;
  const previewCheck = image.previewResult.state === ProcessingState.Error;

  return originalCheck || previewCheck;
};

const deleteImage = (image: Image, closeDialog?: () => void) => {
  Client.deleteImage(image);
  store.deleteImage(image);
  if (closeDialog) closeDialog();
  window.track("Images", "remove_result", "Remove result");
  rbgRemoveImageV100({ image_id: image.meta.id });
};

const requiresDeleteConfirmation = computed(() => {
  const imageHdResult = store.selectedImage.hdResult;
  const isHdResultPresent = imageHdResult !== undefined;
  const isHdResultProcessed =
    imageHdResult?.state === ProcessingState.Processing || imageHdResult?.state === ProcessingState.Finished;

  // we required delete confirmation for any image that is processed and hd is downloaded (or credit is spent processing it)
  return isHdResultPresent && isHdResultProcessed;
});

const imageId = (image: Image) => image?.meta?.id;

const uploadFile = () => {
  window.onUploadFile();
};

const scrollToSelectedImage = () => {
  let child = document.getElementById(imageId(store.selectedImage));
  if (!child) return;

  child.scrollIntoView();
};
</script>

<style scoped>
.image-navigation-wrapper {
  gap: 5px;
}
.image-gallery {
  margin-top: -0.5rem !important;
  padding-top: 0.5rem !important;
}
</style>
