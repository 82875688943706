<template>
  <div class="flex flex-col w-full">
    <!-- Header section -->
    <div class="flex flex-col w-full pb-2 title-section">
      <h2 class="text-2xl font-body">
        <slot name="title" />
      </h2>
      <p class="py-1 text-lg">
        <slot name="subtitle" />
      </p>
    </div>

    <div class="flex w-full items-end gap-1 mb-3">
      <h3 class="price">
        <slot name="price" />
      </h3>
      <span class="text-typo-tertiary">
        <slot name="price-suffix" />
      </span>
    </div>

    <slot name="action" />
    <span
      v-if="$slots['billing-appendix']"
      class="text-typo-tertiary flex justify-center text-2xs !mt-2 billing-appendix"
    >
      <slot name="billing-appendix" />
    </span>
    <hr v-if="$slots['plan-description']" class="divider" />
    <div class="flex flex-col w-full">
      <slot name="plan-description" />
    </div>
  </div>
</template>

<style scoped>
.price {
  font-size: 2rem;
  font-weight: bold;
  margin: 0;
  font-variation-settings: "opsz" 100;
}

.title-section {
  min-height: 9rem;
}

.divider {
  margin: 1.5rem 0;
}

.billing-appendix {
  min-height: 1rem;
}

.price-align-bottom {
  align-items: flex-end;
}
</style>
