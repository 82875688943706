<template>
  <div class="!px-6">
    <h2 class="text-center pt-2 mb-5 mt-4">
      {{ faqHeading }}
    </h2>

    <div class="faq-container border rounded-4xl">
      <AccordionGroup class="px-8" divider="thin">
        <Accordion v-for="(faq, index) in faqs" :key="index">
          <template #title>
            {{ faq.question }}
          </template>
          <template #content><span v-html="faq.answer" /> </template>
        </Accordion>
      </AccordionGroup>
      <div class="px-8 pb-8">
        <Button as="link" href="/help" variant="secondary">
          {{ I18n.t("pricing.visit_faq") }}
          <template #end>
            <OpenInNewIcon />
          </template>
        </Button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { inject } from "vue";
import { FAQ } from "./types";
import { Accordion, Button, OpenInNewIcon, AccordionGroup } from "prism";

defineProps<{
  faqHeading: string;
  faqs: FAQ[];
}>();

const I18n = inject("I18n");
</script>

<style scoped>
.faq-container {
  max-width: 48rem;
  margin: 0 auto;
}
</style>
